import $ from 'jquery';
import 'slick-carousel/slick/slick.min';
import 'what-input';

// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';


$(document).foundation();
jQuery(document).ready(function () {
    console.log('hi coder:)');

    $('.s-class').slick({
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 3
    });

    // sticky header
    $(window).scroll(function () {
        if ($(window).scrollTop() >= 100 && $(window).width() > 768) {
            $('.main-header-navigation-with-bg').addClass('sticky-header');
            $('.sticky-header').slideDown(800);
        } else {
            $('.main-header-navigation-width-bg').removeClass('sticky-header');
        }
    });

    // mobile menu
    $('.menu-btn').click(function () {
        if ($(window).width() < 768) {
            if ($('.menu-btn').hasClass('menu-btn-close')) {
                $('ul.menu').fadeToggle();
                $('.menu-btn').toggleClass('menu-btn-close');
                $('.main-header-navigation-with-bg').toggleClass('open-menu');
                $('html').css('overflow-y', 'auto');
            } else {
                $('.main-header-navigation-with-bg').toggleClass('open-menu');
                $('ul.menu').fadeToggle(300);
                $('.menu-btn').toggleClass('menu-btn-close');
                $('<span class="dropdown right"><i class="fa fa-angle-right"></i></span>').insertAfter('.menu-item-has-children > a');
                $('<span class="dropdown left"><i class="fa fa-angle-left"></i></span>').prependTo('.sub-menu');
                $('li.menu-item-has-children').each(function () {
                    $(this).children('a, span').wrapAll('<span class="span-wrap"></span>');
                });
                $('.dropdown.right').on("click", function () {
                    $(this).parent().next('.sub-menu').show();
                    $('#site-navigation').addClass('open');
                });
                jQuery('.sub-menu').find('.dropdown.left').on("click", function () {
                    $(this).parent().hide();
                    $('#site-navigation').removeClass('open');
                });
                $('html').css('overflow-y', 'hidden');
            }
        }
    });

    if ($(window).width() < 768) {
        $('.menu-item a').click(function () {
            $('.menu-btn').toggleClass('menu-btn-close');
            $('.main-header-navigation-with-bg').removeClass('open-menu');
            $('ul.menu').fadeToggle(300);
            $('html').css('overflow-y', 'auto');
            console.log('click');
        });
    }

    /*
     * Editorial part */


    $(function () {
        $('.editorial-links').parent().addClass('editorial-container');

    });


    /* Close when someone clicks on the "x" symbol inside the overlay */
    $(function () {
        $('.closebtn').click(function () {
            $("#overlay-search").removeClass("open");
            $('#contact-popup-icon').show();
        });
    });


    /*
     * Nav Dropdown Arrow
     */

    $(function navDropdown() {
        if ($(window).width() > 768) {
            $('<span class="dropdown right"><i class="fa fa-angle-right"></i></span>').insertAfter('.menu-item-has-children > a');
            $('li.menu-item-has-children').each(function () {
                $(this).children('a, span').wrapAll('<span class="span-wrap"></span>');
            });
        }
    })

    // Parallax Element on front page
    var parallaxElements = $('.parallax'),
        parallaxQuantity = parallaxElements.length;


    // Create a media condition that targets viewports at least 768px wide
    var mediaQuery = window.matchMedia('(min-width: 768px)')

    // Check if the media query is true
    if (mediaQuery.matches) {
        // Then trigger an alert
        $(window).on('scroll', function () {

            window.requestAnimationFrame(function () {

                for (var i = 0; i < parallaxQuantity; i++) {
                    var currentElement = parallaxElements.eq(i);
                    var scrolled = $(window).scrollTop();

                    currentElement.css({
                        'transform': 'translate3d(0,' + scrolled * -0.2 + 'px, 0)'
                    });

                }
            });

        });

    }

    // modal custom close btn
    $('#toggleBtn').click(function () {
        console.log('hi coder:)');
        var close = $(this).html('<div class="close-button" data-close aria-label="Close modal" type="button"></div>');
        $(this).toggle(close);
    });

    // Sliders - in Gutenberg

    var swiperBlack = new Swiper('.swiper-full', {
        slidesPerView: 1,
        spaceBetween: 10,
        centeredSlides: true,
        observer: true,
        observeParents: true,

        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },

        navigation: {
            nextEl: '.swiper-button-next-1',
            prevEl: '.swiper-button-prev-1',
        },

    });


    // ============ Werke Slick Slider Carousel =============
    $('.werke-slider-container').slick({
        centerMode: true,
        centerPadding: '50px',
        slidesToShow: 3,
        dots: false,
        arrows: true,
        responsive: [
            {
                breakpoint: 1025,
                settings: {
                    arrows: false,
                    dots: true,
                    centerMode: true,
                    centerPadding: '40px',
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 769,
                settings: {
                    arrows: false,
                    dots: true,
                    centerMode: false,
                    centerPadding: '40px',
                    slidesToShow: 1
                }
            }
        ]
    });


    // ============ BIO Slick Slider Carousel =============
    $('.bio-slider-container').slick({
        slidesToShow: 1,
        dots: true,
        arrows: true,
        adaptiveHeight: true
    });


    // ============ Load more events=============


    $('#load-more-events').click(function (event) {

        event.preventDefault();

        let cards = $('.post-card');

        let ids = '';

        $.each(cards, function (key, value) {
            ids += $(value).attr('id') + ',';
        });


        let data = {
            action: 'getMoreEvents',
            ids: ids,
        };


        $.ajax({
            beforeSend: function () {
                $('#load-more-events').css('display', 'none');
                $('.loading').css('display', 'block');
            },
            url: bertaData.ajax_url,
            type: 'POST',
            data: data,
            async: true,
            dataType: "json",
            success: function (res) {

                // when the result came from the back-end (the result comes from the step 2):
                $('.loading').css('display', 'none');   // hide the loader

                $('.events-place').append(res.output);   // add new events in the front-end
                console.log('sucsess');


                if (res.is_more === 1) {
                    $('#load-more-events').removeAttr('style');
                }
            }
        });
    });

    // ============ Load more events=============


    $('#load-more-presse').click(function (event) {

        event.preventDefault();

        let cards = $('.post-card');

        let ids = '';

        $.each(cards, function (key, value) {
            ids += $(value).attr('id') + ',';
        });


        let data = {
            action: 'getMorePresse',
            ids: ids,
        };


        $.ajax({
            beforeSend: function () {
                $('#load-more-presse').css('display', 'none');
                $('.loading').css('display', 'block');
            },
            url: bertaData.ajax_url,
            type: 'POST',
            data: data,
            async: true,
            dataType: "json",
            success: function (res) {

                // when the result came from the back-end (the result comes from the step 2):
                $('.loading').css('display', 'none');   // hide the loader

                $('.presse-place').append(res.output);   // add new events in the front-end
                console.log('sucsess');


                if (res.is_more === 1) {
                    $('#load-more-events').removeAttr('style');
                }
            }
        });
    });

    var bg = document.querySelector('.item-bg');
    var items = document.querySelectorAll('.post-item');
    var item = document.querySelector('.post-item');

    if ($(window).width() > 500) {
        $(document).on("mouseover", ".post-item", function (_event, _element) {

            var newsItem = document.querySelectorAll('.post-item');
            newsItem.forEach(function (element, index) {
                element.addEventListener('mouseover', function () {

                    $('.item-bg').addClass('active');
                    $('.post-item').removeClass('active');

                });

                element.addEventListener('mouseleave', function () {
                    $('.item-bg').removeClass('active');
                    $('.post-item').removeClass('active');
                });

            });

        });
    }

    // Gsap animations
    /// GSAP textbox slide up by scrolling
    gsap.registerPlugin(ScrollTrigger)

    ScrollTrigger.batch(".intro-text-box", {
        //markers: true,
        start: "top bottom",
        onEnter: batch => {
            //gsap.from(batch, {rotation: 360, duration: 2})
            gsap.fromTo(".intro-text-box", {y: 100}, {y: 0, duration: 1.5, ease: "expo"});
        },
        toggleActions: "play none none none"
    });

    // Modal

    var elements = $('.modal-overlay, .modal');

    $('.modal-btn').click(function () {
        elements.addClass('active');
    });

    $('.close-modal').click(function () {
        elements.removeClass('active');
    });

    // modal custom close btn
    $('#toggleBtn').click(function () {
        var close = $(this).html('<div class="close-button" data-close aria-label="Close modal" type="button"></div>');
        $(this).toggle(close);
    });

});

// ==== Vanilla JS ====
const menuItems = document.querySelectorAll('.menu-item')
menuItems.forEach(item => {
    item.addEventListener('mouseover', function () {
        item.classList.add('menu-item__active')
    })
    item.addEventListener('mouseout', function () {
        item.classList.remove('menu-item__active')
    })
})


